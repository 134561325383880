import { useState, useRef, useCallback } from "react";
import * as VideoExpress from "@vonage/video-express";
import useBackgroundBlur from "../hooks/useBackgroundBlur";

const roomContainerId = "roomContainer"

export default function useRoom() {
  const { startBackgroundBlur } = useBackgroundBlur();
  let roomRef = useRef(null);
  let publisherOptionsRef = useRef(null);
  let canvasVideo = useRef(null);
  let canvas = useRef(null);
  let ctx = useRef(null);
  const [camera, setCamera] = useState(null);
  const [screen, setScreen] = useState(null);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [connected, setConnected] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [networkStatus, setNetworkStatus] = useState(null);
  const [cameraPublishing, setCameraPublishing] = useState(false);
  const [pipReady, setPIPReady] = useState(false);

  const addParticipants = ({ participant }) => {
    setParticipants((prev) => [...prev, participant]);
  };

  const removeParticipants = ({ participant }) => {
    setParticipants((prev) =>
      prev.filter((prevparticipant) => prevparticipant.id !== participant.id)
    );
  };

  const addLocalParticipant = ({ room }) => {
    if (room) {
      setLocalParticipant({
        id: room.participantId,
        name: room.participantName,
      });
    }
  };

  const startRoomListeners = useCallback(() => {
    if (roomRef.current) {
      roomRef.current.on("connected", () => {
        console.log("Room: connected");
      });
      roomRef.current.on("disconnected", () => {
        setNetworkStatus("disconnected");
        console.log("Room: disconnected");
      });
      roomRef.current.camera.on("created", () => {
        setCameraPublishing(true);
        console.log("camera publishing now");
      });
      roomRef.current.on("activeSpeakerChanged", (participant) => {
        console.log("Active speaker changed", participant);
      });

      roomRef.current.on("reconnected", () => {
        setNetworkStatus("reconnected");
        console.log("Room: reconnected");
      });
      roomRef.current.on("reconnecting", () => {
        setNetworkStatus("reconnecting");
        console.log("Room: reconnecting");
      });
      roomRef.current.on("participantJoined", (participant) => {
        console.log(participant);
        addParticipants({ participant: participant });
        console.log("Room: participant joined: ", participant);
      });
      roomRef.current.on("participantLeft", (participant, reason) => {
        removeParticipants({ participant: participant });
        console.log("Room: participant left", participant, reason);
      });
    }
  }, []);

  const createCall = useCallback(
    async (
      { apikey, sessionId, token },
      roomContainer,
      userName,
      videoEffects,
      publisherOptions
    ) => {
      if (!apikey || !sessionId || !token) {
        throw new Error("Check your credentials");
      }

      roomRef.current = new VideoExpress.Room({
        apiKey: apikey,
        sessionId: sessionId,
        token: token,
        roomContainer: roomContainerId,
        maxVideoParticipantsOnScreen: 50,
        participantName: userName,
        managedLayoutOptions: {
          layoutMode: "grid",
          screenPublisherContainer: "screenSharingContainer",
        },
      });
      startRoomListeners();

      if (videoEffects.backgroundBlur) {
        const outputVideoStream = await startBackgroundBlur();

        publisherOptionsRef.current = Object.assign({}, publisherOptions, {
          style: {
            buttonDisplayMode: "off",
            nameDisplayMode: "auto",
            audioLevelDisplayMode: "off",
          },

          videoSource: outputVideoStream.getVideoTracks()[0],
          name: userName,
          showControls: true,
        });
      } else {
        publisherOptionsRef.current = Object.assign({}, publisherOptions, {
          style: {
            buttonDisplayMode: "off",
            nameDisplayMode: "auto",
            audioLevelDisplayMode: "off",
          },
          name: userName,
          showControls: true,
        });
      }

      console.log(
        "[useRoom] - finalPublisherOptions",
        publisherOptionsRef.current
      );
      roomRef.current
        .join({ publisherProperties: publisherOptionsRef.current })
        .then(async () => {
          setConnected(true);
          setCamera(roomRef.current.camera);
          setScreen(roomRef.current.screen);
          addLocalParticipant({ room: roomRef.current });
        })
        .catch((e) => console.log(e));

      await PictureInPicture.setUp();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const PictureInPicture = {
    videoWidth: 640,
    videoHeight: 480,
    close: async () => {
      if (!document.pictureInPictureElement) {
        return;
      }

      setPIPReady(false);
      PictureInPicture.stopVideos(canvasVideo.current);
      await document.exitPictureInPicture();
    },

    toggle: async () => {
      if (document.pictureInPictureElement) {
        await PictureInPicture.close();
      } else {
        await PictureInPicture.open();
      }
    },

    open: async () => {
      if (document.pictureInPictureElement) {
        return;
      }

      setTimeout(async () => {
        await canvasVideo.current.requestPictureInPicture();
      }, 1000);
    },

    anim: async () => {
      const videos = Array.from(document.querySelectorAll('.OT_video-element'))

      // Number of participants changed. Recalculating canvas height
      if (videos.length * PictureInPicture.videoHeight !== canvas.current.height) {
        canvas.current.height = videos.length * PictureInPicture.videoHeight
      }

      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];
        ctx.current.drawImage(video, 0, i * PictureInPicture.videoHeight, PictureInPicture.videoWidth, PictureInPicture.videoHeight);
      }

      // if we are still in PiP mode
      if (!pipReady || document.pictureInPictureElement === canvasVideo.current) {
        requestAnimationFrame(() => {
          PictureInPicture.anim();
        })
      }
    },

    stopVideos: () => {
      canvasVideo.current.srcObject.getTracks().forEach(track => track.stop());
    },

    setUp: async () => {
      if ('pictureInPictureElement' in document) {
        const videos = Array.from(document.querySelectorAll('.OT_video-element'));
        const canvasElement = document.createElement('canvas');
  
        canvasElement.width = PictureInPicture.videoWidth
        canvasElement.height = videos.length * PictureInPicture.videoHeight
  
        const context = canvasElement.getContext('2d');
        ctx.current = context;
        // create a new video element for canvas
        const canvasVideoElement = document.createElement('video');
        canvasVideoElement.srcObject = canvasElement.captureStream();
        canvas.current = canvasElement;
        canvasVideo.current = canvasVideoElement;
  
        await PictureInPicture.anim();
        await canvasVideo.current.play();
        setPIPReady(true);
      } else {
        console.info('Your browser doesn\'t support the PiP API.');
      }
    }
  }

  return {
    createCall,
    connected,
    camera,
    screen,
    participants,
    networkStatus,
    cameraPublishing,
    localParticipant,
    pipReady,
    room: roomRef.current,
    togglePIP: PictureInPicture.toggle,
  };
}
